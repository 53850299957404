import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Carousel } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Concrete = () => (
  <Layout>
    <SEO
      title="Utility Installation"
      image={"/utility-installation.jpg"}
      description="Specialists in underground utility installation, backed by our
      experienced staff and extensive range of specialist excavation
      machinery."
    />

    <div className="row g-0">
      <div className="col-lg-6 d-flex align-items-center">
        <div className="content-box">
          <h1 className="section-heading section-heading--left">
            Utility Installation
          </h1>
          <p>
            Specialists in underground utility installation, backed by our
            experienced staff and extensive range of specialist excavation
            machinery.
          </p>
          <p>Trenching and installation of:</p>
          <ul>
            <li>Power</li>
            <li>Communications</li>
            <li>Electrical infrastructure</li>
            <li>Gas</li>
          </ul>
          <p>
            <Link className="btn btn-primary mt-4" to="/contact/">
              Free quote
            </Link>
          </p>
        </div>
      </div>
      <div className="col-lg-6 d-flex align-items-center">
        {/* <Carousel>
              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  src="../images/concrete.jpg"
                  alt="concrete path"
                  loading="eager"
                />
              </Carousel.Item>
            </Carousel> */}
        <StaticImage
          aspectRatio={16 / 10}
          src="../images/utility-installation.jpg"
          alt="Utility Installation"
          loading="eager"
        />
      </div>
    </div>
  </Layout>
);

export default Concrete;
